import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 200,
    }}>
  </div>
);

export default Footer;
