import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import Footer from 'components/Footer';

import base from 'components/base.css';

const Layout = ({ children }) => (
  <>
    <Header />
    <Helmet>
      <title>Kenzo Dozono</title>
    </Helmet>
    <div
      style={{
        width: '100%',
      }}>
      <div
        style={{
          width: 760,
          margin: '0 auto',
          backgroundColor: '#101010',
          padding: 20,
        }}>
        {children}
      </div>
    </div>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
