import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const ContentfulRichText = ({ document }) => {
  const options = {
    // renderNode: {
    //   [BLOCKS.PARAGRAPH]: (node, children) => (
    //     <p>{children}</p>
    //   ),
    //   [BLOCKS.QUOTE]: (node, children) => (
    //     <div className="quotation">{children}</div>
    //   ),
    //   [MARKS.BOLD]: (node, children) => (
    //     <span className="bold">{children}</span>
    //   ),
    // },
  };
  const documentUI = documentToReactComponents(
    document,
    options,
  );

  return (
    <div className="content">
      {documentUI}
    </div>
  );
};

export default ContentfulRichText;
