import React from 'react';
import { Link } from 'gatsby';
import CoverImage from 'images/header.gif';

import headerStyles from 'components/Header.module.css';

const Header = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 20,
    }}>
    <img src={CoverImage} width="800" height="370" />
    <div
      style={{
        display: 'flex',
        flexDirect: 'row',
        alignItems: 'center',
        width: 736,
        padding: '8px 32px',
        backgroundColor: '#333',
        borderTop: 'solid 1px #696969',
      }}>
      <NavLink to="/" style={{ marginLeft: 0 }} >Home</NavLink>
      <NavLink to="/biography" >Biography</NavLink>
      <NavLink to="/karate" >Karate</NavLink>
      <NavLink to="/tai-chi">Tai-Chi</NavLink>
      <NavLink to="/schedule" >Schedule</NavLink>
      <NavLink to="/contact" >Contact</NavLink>
    </div>
  </div>
);

const NavLink = props => (
  <Link
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? headerStyles.navLinkSelected : headerStyles.navLink,
      };
    }}
    {...props}
  />
);

function combineClassNames(classNames) {
  classNames = classNames.filter(x => x);
  return classNames.join(' ');
}

// const NavLink = ({ to, title, style }) => (
//   <Link
//     className={headerStyles.navLink}
//     to={to}
//     href={to}
//     style={{
//       marginLeft: 32,
//       fontSize: 24,
//       ...style,
//     }}>
//     {title}
//   </Link>
// );

export default Header;
